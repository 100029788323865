import Home from './pages/Home';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

export default function App() {
  return (
    <RecoilRoot>
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        <Home />
        {/* @ts-ignore */}
        <GlobalStyles />
      </ThemeProvider>
    </RecoilRoot>
  );
}
