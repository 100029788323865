// prettier-ignore
export const Stage: string = 'pr37';
// prettier-ignore
export const Region: string = 'us-east-2';
// prettier-ignore
export const DevPortalSite: string = 'https://dev-pr37.reach.sh';
// prettier-ignore
export const UserPoolSite: string = 'https://auth-dev-pr37.reach.sh';
// prettier-ignore
export const UserPoolClientId: string = '3pth1pc1j5vqe047q1scavelvd';
// prettier-ignore
export const UserPoolId: string = 'us-east-2_VaAtMl2tB';
// prettier-ignore
export const UserPoolSignInUrlTemplate: string = 'https://auth-dev-pr37.reach.sh/login?client_id=3pth1pc1j5vqe047q1scavelvd&response_type=token&redirect_uri={redirectUrl}';
// prettier-ignore
export const ApiSite: string = 'https://api-dev-pr37.reach.sh';
// prettier-ignore
export const isProd: string = 'false';
