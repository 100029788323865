import { atom, selector, useRecoilValue, RecoilValue } from 'recoil';
import Cookies from 'universal-cookie';
import * as Config from '../config';
import { CognitoJwtVerifier } from 'aws-jwt-verify';

export const signInUrl = Config.UserPoolSignInUrlTemplate.replace(
  '{redirectUrl}',
  encodeURI(`${location.protocol}//${location.host}${location.pathname}`),
);

const cookies = new Cookies();
const RDP_JWT = 'RDP_JWT';

export const stJwt: RecoilValue<string> = atom({
  key: 'Jwt',
  default: (async () => {
    const searchParams = new URLSearchParams(
      window.location.hash.replace('#', ''),
    );
    const jwt_cook = cookies.get(RDP_JWT);
    const jwt_url = searchParams.get('id_token');
    const jwt = jwt_url || jwt_cook;
    return jwt;
  })(),
});

const verifier = CognitoJwtVerifier.create({
  userPoolId: Config.UserPoolId,
  tokenUse: 'id',
  clientId: Config.UserPoolClientId,
});
export const stJwtPayload = selector({
  key: 'JwtPayload',
  get: async ({ get }) => {
    const jwt = get(stJwt);

    console.log(`jwt`, jwt);
    let payload;
    try {
      // XXX they don't seem to last very long, am I doing something wrong? or
      // is the timeout just set very low? Something to do with refresh tokens?
      payload = await verifier.verify(jwt);
    } catch (err) {
      console.error(err);
      return;
    }
    console.log(`jwt payload`, payload);

    // XXX only set if new?
    cookies.set(RDP_JWT, jwt, {
      path: '/',
      expires: new Date(payload.exp * 1000),
      secure: Config.Stage === '',
      sameSite: 'strict',
    });

    return payload;
  },
});

export interface LoginInfoT {
  uid: string;
  email: any;
}
export type LoginInfoTM = LoginInfoT | undefined;
export const stLoginInfo: RecoilValue<LoginInfoTM> = selector({
  key: 'LoginInfo',
  get: async ({ get }) => {
    const payload = get(stJwtPayload);
    if (!payload) return;
    return {
      uid: payload['cognito:username'],
      email: payload.email,
    };
  },
});
