import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

    /*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
  3. Add accessible line-height
*/
* {
  margin: 0;
  padding: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  line-height: calc(1em + 0.5rem);
}
/*
  4. Allow percentage-based heights in the application
*/
html, body, #root {
  height: 100%;
  font-family: 'Poppins';
}
/*
  Typographic tweaks!
  5. Improve text rendering
*/
body {
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root {
  isolation: isolate;
}
`;

export default GlobalStyles;
