import {
  atom,
  selector,
  useRecoilValue,
  useSetRecoilState,
  RecoilState,
  RecoilValue,
} from 'recoil';
import * as Config from '../config';
import { signInUrl, stLoginInfo, LoginInfoT } from '../lib/login';
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Api, api, UserInfoT, ApiKeyViewT } from '@reach-sh/reachp';
import { stJwt, stJwtPayload } from '../lib/login';

const stApiCount: RecoilState<number> = atom({
  key: 'ApiCount',
  default: 0,
});
export const useApiRefresh = () => {
  const setApiCount = useSetRecoilState(stApiCount);
  return () => {
    // XXX This appears to cause crashing
    // setApiCount((x: number) => x + 1);
  }
};

export const stApi: RecoilValue<Api | undefined> = selector({
  key: 'Api',
  get: async ({ get }) => {
    const payload = get(stJwtPayload);
    if (!payload) return;
    const count = get(stApiCount);
    void count;
    const jwt = get(stJwt);
    return api({
      baseURL: Config.ApiSite,
      log: (Config.isProd === 'false'),
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
  },
});

export type UserInfoTM = UserInfoT | undefined;
export const stUserInfo: RecoilValue<UserInfoTM> = selector({
  key: 'UserInfo',
  get: async ({ get }) => {
    const api = get(stApi);
    if (!api) return;
    try {
      return await api.UserInfoG({});
    } catch (e) {
      console.error(e);
    }
  },
})

export type ApiKeysTM = ApiKeyViewT[] | undefined;
export const stApiKeys: RecoilValue<ApiKeysTM> = selector({
  key: 'ApiKeys',
  get: async ({ get }) => {
    const api = get(stApi);
    if (!api) return;
    try {
      return await api.UserApiKeysG({});
    } catch (e) {
      console.error(e);
    }
  },
})

interface ApiKeyProps {
  ak: ApiKeyViewT;
};
interface CreatedProps {
  created: string | undefined;
};
function Created(props: CreatedProps) {
  const { created } = props;
  if (!created) return <></>;
  return (
    <>
      <p>You just created the API key:</p>
      <pre>{created}</pre>
      <p>Write that down! It will never be displayed by us again!</p>
    </>
  );
};
function ApiKey(props: ApiKeyProps) {
  const api = useRecoilValue(stApi);
  const doApiRefresh = useApiRefresh();
  const { ak } = props;
  const { prefix, ctime } = ak;
  const deleteKey = async () => {
    if (!api) return; // XXX disable if no api
    await api.UserApiKeyDelP({ prefix });
    doApiRefresh();
  }
  return (
    <>
      <li>
        <span>
          {prefix}... (created at {ctime})
        </span>
        <span>
          (<button onClick={deleteKey}>rm</button>)
        </span>
      </li>
    </>
  );
};
function ApiKeys() {
  const api = useRecoilValue(stApi);
  const loginInfo = useRecoilValue(stLoginInfo);
  if (!api || !loginInfo) return (<></>);
  const doApiRefresh = useApiRefresh();
  // XXX replace [] with a display that suggests it is still loading
  const apiKeys = useRecoilValue(stApiKeys) || [];
  const [created, setCreated] = useState<string | undefined>(undefined);
  const makeApiKey = async () => {
    const n = await api.UserApiKeyNewP({});
    setCreated(n.actual);
    doApiRefresh();
  }
  return (
    <>
      <p>Your UID is:</p>
      <pre>{loginInfo.uid}</pre>
      <p>Here are your API keys:</p>
      <ul>
        {apiKeys.map((ak: ApiKeyViewT) => (
          <ApiKey key={ak.prefix} ak={ak} />
        ))}
      </ul>
      <p>
        You can create one by clicking
        <button onClick={makeApiKey}>here</button>.
      </p>
      <Created created={created} />
    </>
  )
}

function UserInfo() {
  const userInfo = useRecoilValue(stUserInfo);
  const doApiRefresh = useApiRefresh();
  const api = useRecoilValue(stApi);
  const currentName = userInfo?.name || '';
  return (
    <>
      {userInfo ? (
        <>
          <p>I know your name is {userInfo.name}</p>
        </>
      ) : (
        <>
          <p>You need to set your user information.</p>
        </>
      )}
      <Formik
        initialValues={{ name: currentName }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!api) return;
          await api.UserInfoNameP(values);
          doApiRefresh();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field type='text' name='name' />
            <button type='submit' disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <ApiKeys />
    </>
  );
};

function Login() {
  const loginInfo = useRecoilValue(stLoginInfo);
  if (!loginInfo) {
    return (
      <>
        <p>You are not logged in.</p>
        <p>
          <a href={signInUrl}>Log in</a>
        </p>
      </>
    );
  }
  return (
    <>
      <p>You are logged in!</p>
      <p>Welcome {loginInfo.email}!</p>
      <UserInfo />
    </>
  );
};

export default function Home() {
  return (
    <>
      <h1>{Config.Stage}</h1>
      <p>The real ultimate dev portal.</p>
      <Login />
    </>
  );
};
