import { DefaultTheme } from 'styled-components';

const baseSize = 8;

/**
 *
 * @param value A multiple of the baseSize of 8.
 * @returns value times the baseSize.
 * @reason You can use this function if you need a more granular size ie size(1.5) for 12px.
 */
export const spacing = (value: number) => `${value * baseSize}px`;
export const divet = (value: number) => `polygon(
  0% 0%,
  100% 0%,
  100% 75%,
  100% 100%,
  ${value}% 100%,
  ${value - 10}% calc(100% - 20px),
  0% calc(100% - 20px)
)`;

const theme: DefaultTheme = {};

export default theme;
