// Imports
import fetch from 'cross-fetch';
export * from './index.guard';

// Types
export type Uid = string;
export type nil = {};

// Interfaces

/** @see {isUserInfoT} ts-auto-guard:type-guard */
export interface UserInfoT {
  uid: Uid;
  email?: string;
  name?: string;
};

export type ApiKeyActual = string;

/** @see {isApiKeyRawT} ts-auto-guard:type-guard */
export interface ApiKeyRawT {
  sk: string;
  ctime: string;
}

/** @see {isApiKeyViewT} ts-auto-guard:type-guard */
export interface ApiKeyViewT {
  prefix: string;
  ctime: string;
};

/** @see {isApiKeyFullT} ts-auto-guard:type-guard */
export interface ApiKeyFullT extends ApiKeyViewT {
  actual: ApiKeyActual;
};

/** @see {isUserInfoGD} ts-auto-guard:type-guard */
export type UserInfoGD = nil;
export type UserInfoGR = UserInfoT;

/** @see {isUserInfoNamePD} ts-auto-guard:type-guard */
export interface UserInfoNamePD {
  name: string;
};
export type UserInfoNamePR = nil;

/** @see {isUserApiKeysGD} ts-auto-guard:type-guard */
export type UserApiKeysGD = nil;
export type UserApiKeysGR = ApiKeyViewT[];

/** @see {isUserApiKeyNewPD} ts-auto-guard:type-guard */
export type UserApiKeyNewPD = nil;
export type UserApiKeyNewPR = ApiKeyFullT;

/** @see {isUserApiKeyDelPD} ts-auto-guard:type-guard */
export interface UserApiKeyDelPD {
  prefix: string;
};
export type UserApiKeyDelPR = nil;

// API Class
export interface Config {
  baseURL?: string;
  log?: boolean;
  method?: string;
  body?: string;
  headers?: Record<string, string>;
};

const defaultConfig: Config = {
  baseURL: 'https://api-dev.reach.sh',
};

export type ApiMeth<Dom, Rng> = (dom: Dom, callConfig?: Config) => Promise<Rng>;

export function api(apiConfig?: Config): Api {
  const makeMeth = <Dom, Rng>(
    name: string,
    add: ((dom:Dom, url:string, init:Config) => [ string, Config ]),
  ): ApiMeth<Dom, Rng> => {
    const route = `/${name}`;
    const isGet = name.slice(-1) === "G";
    const methConfig: Config = {
      method: isGet ? 'get' : 'post',
    };
    return async (dom: Dom, callConfig?: Config): Promise<Rng> => {
      const thisConfig: Config = {};
      Object.assign(
        thisConfig,
        defaultConfig,
        apiConfig,
        methConfig,
        callConfig,
      );
      const hs: Record<string, string> = {};
      Object.assign(
        hs,
        defaultConfig?.headers || {},
        apiConfig?.headers || {},
        methConfig?.headers || {},
        callConfig?.headers || {},
      );
      const { baseURL, ...init } = thisConfig;
      init.headers = hs;
      const url = `${baseURL}${route}`;
      const [ urlp, initp ] = add(dom, url, init);
      if ( init.log ) { console.debug(`CALL ${name}`, dom); }
      const resp = await fetch(urlp, initp);
      if ( resp.ok ) {
        // We are trusting the output
        const rng = resp.json() as Rng;
        if ( init.log ) { console.debug(`CALL ${name}`, dom, `=>`, rng); }
        return rng;
      } else {
        throw Error(`${name} call failed: ${resp.body}`);
      }
    };
  };
  const makeMethP = <Dom, Rng>(
    name: string,
  ): ApiMeth<Dom, Rng> => {
    return makeMeth(name, (dom, url, init) => {
      const initp = { ...init, body: JSON.stringify(dom) };
      return [ url, initp ];
    });
  };
  const makeMethG = <Dom extends Record<keyof Dom, string>, Rng>(
    name: string,
  ): ApiMeth<Dom, Rng> => {
    return makeMeth(name, (dom, url, init) => {
      const usp = new URLSearchParams(dom);
      const usps = usp.toString();
      const urlp = `${url}?${usps}`;
      return [ urlp, init ];
    });
  };
  return {
    // NOTE: Add the lambda here
    UserInfoG: makeMethG<UserInfoGD, UserInfoGR>('UserInfoG'),
    UserInfoNameP: makeMethP<UserInfoNamePD, UserInfoNamePR>('UserInfoNameP'),
    UserApiKeysG: makeMethG<UserApiKeysGD, UserApiKeysGR>('UserApiKeysG'),
    UserApiKeyNewP: makeMethP<UserApiKeyNewPD, UserApiKeyNewPR>('UserApiKeyNewP'),
    UserApiKeyDelP: makeMethP<UserApiKeyDelPD, UserApiKeyDelPR>('UserApiKeyDelP'),
  };
};

// NOTE: Add the lambda here
export interface Api {
  UserInfoG: ApiMeth<UserInfoGD, UserInfoGR>;
  UserInfoNameP: ApiMeth<UserInfoNamePD, UserInfoNamePR>;
  UserApiKeysG: ApiMeth<UserApiKeysGD, UserApiKeysGR>;
  UserApiKeyNewP: ApiMeth<UserApiKeyNewPD, UserApiKeyNewPR>;
  UserApiKeyDelP: ApiMeth<UserApiKeyDelPD, UserApiKeyDelPR>;
};

